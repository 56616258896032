import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from './ui/Head';
import { WILD_SULTAN } from '../variables';
import Fade from '@mui/material/Fade';

const Loader = ({ loadingProgress, alternateRefs }) => {
    const router = useRouter();

    useEffect(() => {
        let timer = setTimeout(() => router.push('/error?code=408'), 30000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Fade in timeout={800}>
            <div className="app-loader" style={{ opacity: loadingProgress === 100 ? 0 : 1 }}>
                <Head title={WILD_SULTAN} alternateRefs={alternateRefs} />
                <span className="app-loader--logo" />
                <div className="loading-wrapper">
                    <div className="progress-bar">
                        <span style={{ width: `${loadingProgress}%` }} />
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Loader;
